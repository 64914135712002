const ready = (callback) => {

	if (document.readyState != 'loading') callback();
	else document.addEventListener('DOMContentLoaded', callback);

}


// Doc ready

ready(() => {


	const toggler       = document.querySelector('.navbar-toggler');
	let navbarHeight    = $('#header').outerHeight(true);
	let delta           = 5;
	let lastScrollTop   = 0;
	let togglerOpen     = false;
	let didScroll;


	function getWindowWidth() {
		return $( window ).width();
	}

	function togglerState() {
		togglerOpen = !togglerOpen;
	}

	function hasScrolled() {

		if(getWindowWidth() < 991) {

			let scrollTop = $(this).scrollTop();

			// Delta min scroll
			if (Math.abs(lastScrollTop - scrollTop) <= delta) {
				return;
			}

			// If top remove scrolldown
			if (scrollTop <= 0) {
				$('body').removeClass('scroll-down')
				return;
			}

			if (scrollTop > lastScrollTop && scrollTop > navbarHeight) {
				// Scroll Down
				if (!togglerOpen) {
					$('[data-bs-toggle="dropdown"]').dropdown('hide').blur();
					$('body').removeClass('scroll-up').addClass('scroll-down');
				}
			} else {
				// Scroll Up
				if (!togglerOpen) {
					if (scrollTop + $(window).height() < $(document).height()) {
						$('body').removeClass('scroll-down').addClass('scroll-up');
					}
				}
			}

			lastScrollTop = scrollTop;

		}
	}

	toggler.addEventListener('click', togglerState);



	$(window).scroll(function(){
		if (!togglerOpen) {
			didScroll = true;
		}
	});

	$(window).on('resize', function(){
		$('body').removeClass('scroll-down').addClass('scroll-up');
	});

	// Toggle scroll function state for collapse and anchor links
	$('#header [data-bs-toggle="collapse"]').click(function() {
		togglerOpen = true;
	});
	$('.navbar-collapse').on('hidden.bs.collapse', function () {
		togglerOpen = false;
	});
	$('.navbar-collapse a[href^="#"]:not([href="#"])').click(function () {
		togglerOpen = true;
	});


	// Scroll func performance
	setInterval(function() {
		toggleBacktotop();
		if (didScroll) {
			hasScrolled();
			didScroll = false;
		}
	}, 250);




	// SMOOTH SCROLL TO ANCHORS

	const anchors = document.querySelectorAll('a[href^="#"]:not([href="#"])');

	anchors.forEach(trigger => {

		trigger.onclick = function(e) {

			e.preventDefault();
			let hash = this.getAttribute('href');
			let target = document.querySelector(hash);
			let elementPosition = target.offsetTop;
			let headerOffset = $('#header').outerHeight(true);
			let offsetPosition = elementPosition - headerOffset;

			window.scrollTo({

				top: offsetPosition,
				behavior: 'smooth'

			});

			// Wait scroll function to resume scroll func
			setTimeout(function(){
				togglerOpen = false;
			}, 1000);

		};
	});




	// BACK TO TOP

	const topButton = document.getElementById('top');

	function backToTop() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	function toggleBacktotop() {
		if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
			topButton.style.display = 'block';
		} else {
			topButton.style.display = 'none';
		}
	}

	if(topButton) {
		topButton.addEventListener('click', backToTop);
	}




	// LAZY LOADING IMAGES

	let lazyloadImages;

	if ('IntersectionObserver' in window) {

		lazyloadImages = document.querySelectorAll('.lazyload');

		let imageObserver = new IntersectionObserver( function(entries, observer) {

			entries.forEach((entry) => {

				if (entry.isIntersecting) {

					let image = entry.target;
					image.src = image.dataset.src;
					image.classList.remove('lazyload');
					image.classList.add('lazyloaded');
					imageObserver.unobserve(image);

				}

			});

		}, {
			rootMargin: "300px 0px 300px 0px",
			threshold: 0
		});

		lazyloadImages.forEach(function(image) {

			imageObserver.observe(image);

		});

	} else {

		let lazyloadThrottleTimeout;

		lazyloadImages = document.querySelectorAll('.lazyload');

		function lazyload () {

			if(lazyloadThrottleTimeout) {

				clearTimeout(lazyloadThrottleTimeout);

			}

			lazyloadThrottleTimeout = setTimeout(function() {

				let scrollTop = window.pageYOffset;

				lazyloadImages.forEach(function(img) {

					if(img.offsetTop < (window.innerHeight + scrollTop + 300)) {

						img.src = img.dataset.src;
						img.classList.remove('lazyload');
						img.classList.add('lazyloaded');

					}

				});

				if(lazyloadImages.length == 0) {

					document.removeEventListener('scroll', lazyload);
					window.removeEventListener('resize', lazyload);
					window.removeEventListener('orientationChange', lazyload);

				}

			}, 20);

		}

		document.addEventListener('scroll', lazyload);
		window.addEventListener('resize', lazyload);
		window.addEventListener('orientationChange', lazyload);
	}





	// SWIPER CAROUSELS

	const swiper = new Swiper('.swiper-cat', {

		loop: true,
		// rewind: true,
		slidesPerView: 1,
		spaceBetween: 10,
		watchSlidesProgress: true,
		lazy: {
			loadPrevNext: true,
		},
		grabCursor: true,
		autoplay: {
			delay: 4500,
		},
		breakpoints: {
			1200: {
				slidesPerView: 4
			},
			991: {
				slidesPerView: 3
			},
			576: {
				slidesPerView: 2
			}
		}
	});

	const swiper1 = new Swiper('.swiper-home', {

		loop: true,
		// rewind: true,
		centeredSlides: true,
		spaceBetween: 10,
		slidesPerView: 1,
        grabCursor: true,
		autoplay: {
			delay: 4500,
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},

	});

	const swiper2 = new Swiper('.swiper-posts', {

		// loop: true,
		rewind: true,
		slidesPerView: 1,
		spaceBetween: 20,
        grabCursor: true,
		watchSlidesProgress: true,
		lazy: {
			loadPrevNext: true,
		},
		autoplay: {
			delay: 4500,
		},
		breakpoints: {
			767: { slidesPerView: 2 },
			991: { slidesPerView: 3 },
			1200: { slidesPerView: 4 }
		},
		pagination: {
			el: '.pagination',
			clickable: true,
			bulletClass: 'page-item',
			bulletActiveClass: 'active',
			renderBullet: function (index, className) {
				return '<li class="page-item"><a class="page-link" href="#">' + (index + 1) + '</a></li>';
			},
		},

	});



});